<template>
  <div>
    <div class="h3">
      ระบบ Logs
    </div>
    <div class="card">
      <div class="card-header">
        <div class="card-title">
          รายการ Logs (ฝาก-ถอน)
        </div>
      </div>
      <div class="card-body">
        <div class="row no-gutters">
          <div class="d-flex">
            <div class="d-flex align-start">
              <div class="btn-disable">
                วันที่ (จาก)
              </div>
            </div>

            <flat-pickr
              v-model="dateStart"
              class="form-control"
              placeholder="เลือกวันที่"
            />
          </div>
          <div class="d-flex">
            <div class="d-flex align-start">
              <div class="btn-disable">
                วันที่ (ถึง)
              </div>
            </div>

            <flat-pickr
              v-model="dateEnd"
              class="form-control"
              placeholder="เลือกวันที่"
            />
          </div>
          <div class="d-flex">
            <div class="d-flex align-start">
              <div class="btn-disable">
                ค้นหา
              </div>
            </div>

            <b-form-input v-model="term" />
          </div>
          <button
            class="btn btn-primary ml-1"
            @click="search()"
          >
            ค้นหา
          </button>
        </div>
      </div>
      <b-table
        striped
        hover
        small
        responsive
        show-empty
        class="mt-2 position-relative items-center"
        :per-page="perPage"
        :items="items"
        :fields="fields"
      >

        <template #empty="scope">
          <div class="p-2 text-center">
            <img
              src="/box.png"
              alt="empty"
              height="40px"
            > {{ scope.emptyFiltered ? 'No matching records found' :
              'ไม่พบข้อมูล' }}
          </div>
        </template>
        <template #cell(description)="{ item }">
          {{ item.bank === 'bonus' ? `โบนัส ${item.from}` : item.description }}
        </template>
        <template #cell(updated_at)="{ item }">
          {{ item.updated_at | dateFormat }}
        </template>
        <template #cell(type)="{ item }">
          <span
            v-if="item.type === 'dep'"
            class="text-success"
          >
            <feather-icon icon="ArrowUpRightIcon" />
            ฝาก
          </span>
          <span
            v-else
            class="text-danger"
          >
            <feather-icon icon="ArrowDownLeftIcon" />
            ถอน
          </span>
        </template>
      </b-table>
      <div
        v-if="isLoading"
        class="text-center mt-2"
      >
        <b-spinner
          variant="primary"
          type="grow"
          label="Spinning"
        />
        <b-spinner
          variant="primary"
          type="grow"
          label="Spinning"
        />
        <b-spinner
          variant="primary"
          type="grow"
          label="Spinning"
        />
      </div>
      <div class="card-body d-flex justify-content-between flex-wrap">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="getData()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="page"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getData()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import BankStatus from './bank-status.vue';
// import TransactionListing from './transaction-listing.vue';
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment-timezone'
import {
  BFormInput, BTable, BSpinner, BFormGroup, BPagination, BFormSelect,
} from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
    BTable,
    BSpinner,
    BFormGroup,
    BPagination,
    BFormSelect,
    // TransactionListing,
    flatPickr,
  },
  filters: {
    dateFormat(val) {
      return moment(val).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm')
    },
  },
  data: () => ({
    perPage: 10,
    pageOptions: [10, 50, 100, 'all'],
    totalRows: 10,
    page: 1,
    items: [],
    term: '',
    fields: [
      { key: 'tel', label: 'เบอร์มือถือ' },
      { key: 'before_balance', label: 'กระเป๋าเงิน(ก่อน)' },
      { key: 'amount', label: 'จำนวนเงิน(ดำเนินการ/หลัง)' },
      { key: 'type', label: 'ประเภท' },
      // { key: 'from', label: 'จาก' },
      // { key: 'promotion', label: 'โปรโมชั่น' },
      { key: 'description', label: 'รายละเอียด' },,
      { key: 'updated_at', label: 'วันที่' },
      { key: 'by', label: 'ทำรายการโดย' },
    ],
    isLoading: false,
    dateStart: moment().tz('Asia/Bangkok').clone().subtract(3, 'days')
      .format('YYYY-MM-DD'),
    dateEnd: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
  }),
  computed: {
    agent() {
      return this.$store.state.app.agent
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.items = []
      this.isLoading = true
      try {
        const { data } = await this.$http.get('/logs/depositWithdraw', {
          params: {
            page: this.page, perPage: this.perPage, dateStart: this.dateStart, dateEnd: this.dateEnd, type: this.type, term: this.term,
          },
        })
        this.totalRows = (this.perPage === 'all') ? data.totalPages : data.total
        this.items = data.data
        // console.log(data)
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    search() {
      this.page = 1
      this.getData()
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
